import React from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { useSession } from '@vtex/store-sdk'
import { Helmet } from 'react-helmet'
import {
  Card,
  Container,
  Flex,
  Grid,
  Skeleton,
  SuspenseSSR,
} from '@vtex/store-ui'
import Layout from 'src/components/Layout'
import DefaultProductView from 'src/views/product'
import { ProductPageProvider } from 'src/contexts/ProductPageContext'
import { useAsyncProduct } from 'src/views/product/components/useAsyncProduct'
import type { Product } from 'src/typings'

interface IPage extends PageProps {
  data: { product: Product }
  slug: string
}

const ProductPage: FC<IPage> = (props) => {
  const { slug } = props

  const { channel } = useSession()
  const { product } = useAsyncProduct({
    slug,
    regionId: null,
    salesChannel: Number(channel),
  })

  const data = {
    ...props,
    data: { product },
  }

  return (
    <ProductPageProvider product={product} slug={props.slug}>
      <DefaultProductView {...data} />
    </ProductPageProvider>
  )
}

const Page: FC<IPage> = (props) => {
  const { pathname } = props.location

  const canonicalMetatag = `https://www.avon.com.br/${pathname}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')
    .replace('/p/', '/p')

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalMetatag} />
      </Helmet>
      <Layout>
        <SuspenseSSR
          fallback={
            <Container>
              <Flex variant="productPage.container">
                <Skeleton width="500px" height="45px" />
                <Grid my={4} mx="auto" gap={[0, 3]} columns={[1, 2]}>
                  <Skeleton width="500px" height="500px" />
                  <Card>
                    <Skeleton width="500px" height="20px" />
                    <Skeleton width="500px" height="20px" />
                    <Skeleton width="500px" height="20px" />
                    <Skeleton width="500px" height="20px" />
                  </Card>
                </Grid>
              </Flex>
            </Container>
          }
        >
          <ProductPage {...props} />
        </SuspenseSSR>
      </Layout>
    </>
  )
}

export default Page
